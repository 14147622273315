function ChangeLog() {
    return (
        <div>
            <div id="hero">
                <h2 id="tagline">Change Log</h2>
            </div>

            <main className="container p-4">
                <h4>v1.0.10</h4>
                <ul>
                    <li>Beta real time AI translations for all videos.</li>
                    <li>Translation notes to help learners understand idiomatic subtleties.</li>
                    <li>Fixed bugs and improved user experience.</li>
                </ul>

                <h4>v1.0.9</h4>
                <ul>
                    <li>Lingo Layer is a language learning tool but the UI was only available in English. What?? Translations for the UI are now included so you can use the extension in the language of your choice.</li>
                    <li>Fixed a bookmarks bug that occurred due to Netflix changes.</li>
                    <li>Cleaned up the interface and intro modals.</li>
                </ul>
                
                <h4>v1.0.8</h4>
                <ul>
                    <li>Added several enhancements for ad-supported sessions.</li>
                    <li>Previously, when a video first loaded, the seek-to-next-subtitle button would not work until the first subtitle was played. The button now works as expected immediately upon loading.</li>
                    <li>In rare cases, loading a text track would result in an error due to Netflix updates to the tracks source list. This bug has been fixed.</li>
                </ul>

                <h4>v1.0.7</h4>
                <ul>
                    <li>Fixed a bug on for ad-supported sessions that erroneously played subtitles during ads</li>
                </ul>

                <h4>v1.0.6</h4>
                <ul>
                    <li>Added support for browser-specific update checks</li>
                </ul>

                <h4>v1.0.5</h4>
                <ul>
                    <li>Fixed bugs with subtitles not playing in certain edge cases</li>
                    <li>In rare cases, when loading of subtitles times out, prompt the user to reload the page</li>
                </ul>

                <h4>v1.0.4</h4>
                <ul>
                    <li>Fixed issue with subtitles not displaying after periods of inactivity</li>
                </ul>

                <h4>v1.0.3</h4>
                <ul>
                    <li>Better defaults for language display colors</li>
                    <li>Bug fixes</li>
                </ul>

                <h4>v1.0.2</h4>
                <ul>
                    <li>Fixes for initial language selection</li>
                </ul>

            </main>  
        </div>
    );
}

export default ChangeLog;